module.exports = {
    siteUrl: `https://blog.drive2.city`, // Site domain. Do not include a trailing slash!

    postsPerPage: 5, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `DRIVE2.CITY | Blog`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Online check of LEZ accessibility in EU cities. Get the directions that meet local access regulations. Explore the Park & Ride alternatives, stay mobile.`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `DRIVE2.CITY`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `d2c-icon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#f4f4f4`, // Used for Offline Manifest
    themeColor: `#204fa4`, // Used for Offline Manifest
}
