import React from "react"

import addToMailchimp from 'gatsby-plugin-mailchimp'


export default class NewsletterForm extends React.Component {
    state = {
        email: "",
      }
    
    handleInputChange = e => {
        const target = e.target
        const value = target.value
        const name = target.name
    
        this.setState({
          [name]: value,
        })
    }

    handleSubmit = e => {
        e.preventDefault()
    
        addToMailchimp(this.state.email)
          .then(data => {
            alert(data.msg)
          })
          .catch(error => {
            console.error(error)
          })
    }
    

    render() {
        return (
                <form className="newsletter__form" onSubmit={ this.handleSubmit }>
                    <input type="email"
                        name="email"
                        defaultValue={ this.state.email }
                        onChange={ this.handleInputChange }
                        className="textinput--newsletter"
                        placeholder="A place for your email"
                        maxlength="255"/>            
                    <input type="submit" className="button--newsletter" value="Submit"/>
                </form> 
        )
    }
}