import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '.'
import config from '../../utils/siteConfig'
import NewsletterForm from '../helpers/newsletterForm'

// Styles
import '../../scss/main.scss'

import logoFooter from '../../images/logo-d2c--right.svg'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookSquare, faTwitterSquare, faInstagram} from '@fortawesome/free-brands-svg-icons'
import { faCaretDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'



/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome, dynamicTitle, dynamicDescription }) => {
    const site = data.allGhostSettings.edges[0].node
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>

            {/* Same for all pages - header with logo and menu list */}
            <Fragment>

            
            <header className="header">
                <div className="header__alertbox">
                    <strong>In 2020, many European cities are extending the driving bans.&nbsp;  
                    <a href="#" className="more">Learn more</a><FontAwesomeIcon icon={faArrowRight} className="more__icon" /></strong>
                </div> 
                <div className="container">
                    <div className="header__inner">
                    <a href="/" className="header__logo">                        
                    </a>                    
                    <nav className="menu" role="navigation" aria-label="Main navigation">                   
                    <a href="https://app.drive2.city" class="city-selector--header header__search-input" >select the city <FontAwesomeIcon icon={faCaretDown} className="city-selector--header__icon-input" /></a>  
                    </nav>
                    </div>
                </div>
            </header> 

            {/* different if isHome or not */}
            {isHome ? 
            <Fragment>
            <section className="hero-section" role="banner">    
                <div className="container hero-section__inner">                    
                    <div className="hero-section__title">
                        <h1>{site.title}</h1>
                    </div>
                    <div className="hero-section__description">
                        <p>{site.description}</p>
                    </div>                            
                </div>                
                <div className="hero-section__separator">
                </div>
            </section>

            <div className="fastlink-section">
                <div className="container fastlink-section__inner">
                    <a href="" className="fast-card">                        
                        <Img fluid={data.UmweltImg.childImageSharp.fluid} className="fast-card__picture" alt="testimage" />
                        <div className="fast-card__text-wrapper">
                        <span className="fast-card__topic">topic 1</span>
                        <h3 className="fast-card__header">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h3>
                        <div className="fast-card__footer">
                        <span className="fast-card__date">01.02.2020</span>
                        <span className="fast-card__read">5 min read</span>
                        </div>
                        </div>                      
                    </a>

                    <a href="" className="fast-card">
                        <Img fluid={data.UmweltImg.childImageSharp.fluid} className="fast-card__picture" alt="testimage" />
                        <div className="fast-card__text-wrapper">
                        <span className="fast-card__topic">topic 3</span>
                        <h3 className="fast-card__header">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h3>
                        <div className="fast-card__footer">
                        <span className="fast-card__date">02.02.2020</span>
                        <span className="fast-card__read">2 min read</span>
                        </div>
                        </div> 
                    </a>

                    <a href="" className="fast-card">
                        <Img fluid={data.UmweltImg.childImageSharp.fluid} className="fast-card__picture" alt="testimage" />
                        <div className="fast-card__text-wrapper">
                        <span className="fast-card__topic">topic 6</span>
                        <h3 className="fast-card__header">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h3>
                        <div className="fast-card__footer">
                        <span className="fast-card__date">01.02.2020</span>
                        <span className="fast-card__read">3 min read</span>
                        </div>
                        </div>                         
                    </a>
                </div>
            </div>

            <nav className="blognavi-section"> 
                <div className="container blognavi-section__inner">       
                <nav className="blog-navigation">
                    <Link to="/tag/topic1/" className="blog-navigation__item"><img src="" className="blog-nav__symbol"></img>Topic 1</Link>
                    <Link to="/tag/topic2/" className="blog-navigation__item"><img src="" className="blog-nav__symbol"></img>Topic 2</Link>
                    <Link to="/tag/topic2/" className="blog-navigation__item"><img src="" className="blog-nav__symbol"></img>Topic 3</Link>
                    <Link to="/tag/topic2/" className="blog-navigation__item"><img src="" className="blog-nav__symbol"></img>Topic 4</Link>
                    <Link to="/tag/topic2/" className="blog-navigation__item"><img src="" className="blog-nav__symbol"></img>Topic 5</Link>
                    <Link to="/tag/topic2/" className="blog-navigation__item"><img src="" className="blog-nav__symbol"></img>Topic 6</Link>
                </nav>
                </div>                       
            </nav>  

            </Fragment>
            :
            <section className="hero-section" role="banner">    
                <div className="container hero-section__inner">
                    <div className="hero-section__title">
                        <h1>{dynamicTitle}</h1>
                    </div>
                    <div className="hero-section__description">
                        <p>{dynamicDescription}</p>
                    </div>                              
                </div>                
                <div className="hero-section__separator"></div>
            </section>        
            }             

            <main className="content-section">
                {/* All the main content gets inserted here, index.js, post.js */}
                {children}
            </main>

                
            {/* The footer at the very bottom of the screen */}

            <section className="colored-section"> 
                <div className="colored-section__separator--top"></div>
                <div className="container colored-section__inner">
                    <div className="colored-section__title">                  
                        <h2>Ready to go (drive)?</h2>
                    </div>  
                        <a href="https://app.drive2.city" class="city-selector--color-section" >select the city <FontAwesomeIcon icon={faCaretDown} className="icon--search-input" /></a>                                       
                </div>
            </section>

            <footer className="footer-section">
                <div className="container">                    
                    <div className="row">

                        <div className="col-xs-12 col-md-4 pr-1">                                                  
                            <div className="footer-section__author-box">                                
                                <img className="footer-section__logo" src={logoFooter} alt="logo of 'drive2city'"></img>
                                <Img fluid={data.authorImg.childImageSharp.fluid} className="footer-section__author-foto" alt="portrait of 'Tomas Novotny'" />
                                <div className="d-flex flex-column justify-content-end align-items-start">
                                    <span className="small">handcrafted by</span>
                                    <h6 className="category-title">Tomas Novotny</h6>
                                </div>                                
                            </div>                          
                            <p className="footer-section__author-word">„At the beginning I started with a small tool, just for my personal use. Recently, many cities have changed their frameworks for reducing the congestion. By starting the DRIVE2.CITY, I am providing a compact service for the other car owners - keeping them mobile.”</p>          
                        </div>

                        <div className="col-xs-12 col-md-3">
                            <h6 className="category-title category-title--alone">fast navigation</h6>
                            <ul className="navigation-links">
                                <li className="link-item"><a href="/#lez-strategy">About reducing congestion</a></li>
                                <li className="link-item"><a href="/#zone-overview">Important links for drivers</a></li>
                                <li className="link-item"><a href="/#key-futures">Key futures of DRIVE2.CITY</a></li>  
                                <li className="link-item"><a href="/#compability">Integrations and compablity</a></li>                                                                                         
                            </ul>
                        </div>

                        <div className="col-xs-12 col-md-5 section--footer__newsletter">
                            <h6 className="category-title category-title--alone">newsletter</h6>
                            <span className="footer-section__newsletter-msg">Stay updated about new functions.</span>
                            {/* Here must be newsletter form */}
                            <NewsletterForm />
                            <div className="footer-section__newsletter-agreement">                                
                                <span className="note">By clicking on Submit I agree with the <a href="/privacy-policy">Privacy Policy.</a></span>
                            </div>                           
                        </div>

                    </div>
                </div>
            </footer>   
            
            <footer className="credentials-section">
                <div className="container">
                <div className="row justify-content-between">                 

                    <div className="col-12 col-md-3 text-center">
                        <a href="mailto:ask@drive2.city" className="credentials-section__email">ask@drive2.city</a>
                        <ul className="social-links">
                            <li className="social-item"><a href="https://www.facebook.com/app.drive2.city/" target="_blank"><FontAwesomeIcon icon={faFacebookSquare} /></a></li>
                            <li className="social-item"><a href="" target="_blank"><FontAwesomeIcon icon={faTwitterSquare} /></a></li>
                            <li className="social-item"><a href="https://www.instagram.com/drive2.city/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
                        </ul> 
                    </div>

                    <div className="col-12 col-md-5 order-md-first credentials-section__copyrhight">
                        <span className="credentials-section__title">© drive2city 2020 - All rights reserved</span>
                        <ul className="legal-links">
                            <li className="link-item"><a href="/imprint">Imprint</a></li>
                            <li className="link-item"><a href="/privacy-policy">Privacy Policy</a></li>
                            <li className="link-item"><a href="/terms-of-service">Terms of Service</a></li>
                        </ul> 
                    </div>

                </div>
                </div> 
            </footer>

            </Fragment> 
        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                authorImg: file(relativePath: { eq: "author_foto.png" }) {
                    childImageSharp {
                      fluid(maxWidth: 60) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
                UmweltImg: file(relativePath: { eq: "umwelt_city_sign.png" }) {
                    childImageSharp {
                      fluid(maxWidth: 860) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }            
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
