import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    return (
        <Link to={url} className="postcard">
            
            {post.feature_image &&
            <div className="postcard__image" style={{
                        backgroundImage: `url(${post.feature_image})` ,}}>
            </div>}
            
            <div className="postcard__tags">
            {post.tags && <Tags post={post} visibility="public" autolink={false} />}
            </div>

            <h2 className="postcard__title">{post.title}</h2>
                        
            <footer className="postcard__footer">
                <div className="left">  
                    {post.published_at_pretty}              
                </div>
                <div className="right">
                    {readingTime}
                </div>
            </footer>
        </Link>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        published_at_pretty: PropTypes.string,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
